import { Injectable } from '@angular/core'
import { AuthService, User } from '@auth0/auth0-angular'
import { LandingService, PingService, UserService } from '@cts/cedar-public-api'
import { AppConfig } from 'projects/cedar-shared/src/app-config/app-config'
import { ImpersonationInterceptor } from 'projects/cedar-shared/src/interceptors/impersonation.interceptor'
import { BehaviorSubject, firstValueFrom } from 'rxjs'

export interface AccountDetailsModel {
  userName: string
  companyName: string | null
}

export interface UserProfileModel {
  idToken: User
  isImpersonated: boolean
  accountDetails: AccountDetailsModel
}

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  public isAuthenticated: boolean = false

  private userSubject$ = new BehaviorSubject<UserProfileModel | null>(null)
  public user$ = this.userSubject$.asObservable()
  public user: UserProfileModel | null = null

  constructor(
    private _: PingService,
    private userService: UserService,
    private landingService: LandingService,
    private authService: AuthService,
    private impersonationService: ImpersonationInterceptor,
    private appConfig: AppConfig
  ) {}

  async initialise() {
    this.isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$)

    var model: UserProfileModel | null = null

    if (this.isAuthenticated) {
      var profile = await firstValueFrom(this.userService.getUserProfile())
      var idToken = await firstValueFrom(this.authService.user$)

      model = {
        idToken: idToken!,
        isImpersonated: !!profile.isImpersonated,
        accountDetails: {
          userName: profile.userName || '',
          companyName: profile.companyName || null
        }
      }
    }
    this.user = model
    console.log('publishing new user model')
    //elements inside root app component (and it's children)
    // but not part of the router outlet, will already have been instantiated,
    // so they need to get this information via subscription
    this.userSubject$.next(model)
  }

  async performSingleSignOn(actor: string): Promise<boolean | string> {
    console.log('performing single sign-on')

    this.isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$)

    if (!this.isAuthenticated) {
      return 'log-in'
    }

    var actorSet = false

    if (actor) {
      actorSet = this.impersonationService.trySetActorId(actor)
      if (!actorSet) {
        console.warn('failed to set actorId ', actor)
      }
    }

    if (actorSet) {
      console.log('actor id set')
      // this hack enables impersonated session
      // to get an up-to-date version of their profile from wldl backend

      try {
        await firstValueFrom(this.landingService.syncUserDetails())
        console.log('landing call completed')
      } catch {
        return 'error'
      }
    } else {
      this.impersonationService.clearActorId()
      console.log('actor id (if any) cleared')
    }

    return '/latest-data'
  }

  async performLogin(showAsSignup: boolean) {
    console.log('performing login')
    var currentPath = window.location.href.substring(window.location.origin.length)

    var options = {}
    var noRedirectFor = ['/log-in', '/error']
    if (!noRedirectFor.find((p) => p === currentPath)) {
      options = { ...options, ...{ appState: { target: currentPath } } }
    }
    if (showAsSignup) {
      options = { ...options, ...{ authorizationParams: { screen_hint: 'signup' } } }
    }
    await firstValueFrom(this.authService.loginWithRedirect(options))
  }

  async performLogout(): Promise<boolean> {
    console.log('performing logout')
    if (this.impersonationService.isImpersonating) {
      // for impersonation, don't actually log the user out, because they
      // still need to be logged in in the admin app. So just clear the impersonation ID
      this.impersonationService.clearActorId()
      location.href = this.appConfig.adminAppUri
    } else
      try {
        await firstValueFrom(
          this.authService.logout({ logoutParams: { returnTo: this.appConfig.logoutUri } })
        )
      } catch (error) {
        // clear out any stored state in case auth0 couldn't do it for us.
        console.error(error)
        localStorage.clear()
        location.href = this.appConfig.logoutUri
      }
    return false
  }
}
