export * from './landing.service';
import { LandingService } from './landing.service';
export * from './ping.service';
import { PingService } from './ping.service';
export * from './releaseData.service';
import { ReleaseDataService } from './releaseData.service';
export * from './releaseVersion.service';
import { ReleaseVersionService } from './releaseVersion.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [LandingService, PingService, ReleaseDataService, ReleaseVersionService, UserService];
