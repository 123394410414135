import { Injectable } from '@angular/core'
import { ReleaseDataService, ReleaseVersionService } from '@cts/cedar-public-api'
import { firstValueFrom } from 'rxjs'

import { ReleaseVersionInterceptor } from './release-version.interceptor'

export class ReleaseVersionsModel {
  constructor(
    private service: ReleasesService,
    ctsCurrentId: number
  ) {
    this._selectedReleaseId = this.ctsCurrentId = ctsCurrentId
  }

  private _selectedReleaseId: number | null

  get selectedReleaseId(): number | null {
    return this._selectedReleaseId
  }
  set selectedReleaseId(value: number) {
    if (value != this.selectedReleaseId) {
      this._selectedReleaseId = value
      this.service.onCurrentReleaseIdChanged(value)
    }
  }

  public ctsCurrentId: number = 0
  public availableVersions: ReleaseVersion[] = []
}

export class ReleaseVersion {
  public id: number = 0
  public displayLabel: string = ''
}

@Injectable({ providedIn: 'root' })
export class ReleasesService {
  public releases = new ReleaseVersionsModel(this, 0)

  constructor(
    private releaseVersionService: ReleaseVersionService,
    private releaseVersionInterceptor: ReleaseVersionInterceptor,
    private releaseDataService: ReleaseDataService
  ) {}

  async initialise(): Promise<boolean | string> {
    console.log('fetching release version(s)')
    var serverModel = await firstValueFrom(this.releaseVersionService.getVersions())
    var ctsCurrentId = serverModel.find((release) => !!release.isCurrent)?.id || 0
    var releases = new ReleaseVersionsModel(this, ctsCurrentId)
    releases.availableVersions = serverModel.map((release) => ({
      id: release.id || 0,
      displayLabel: release.displayLabel || ''
    }))

    this.releases = releases
    this.releaseVersionInterceptor.clear()

    if (releases.ctsCurrentId == 0) {
      console.error('No releases available.')
      return 'error'
    } else if (this.releases.availableVersions.length > 1) {
      return 'my/select-release-version'
    }

    this.releaseVersionInterceptor.set(this.releases.ctsCurrentId)
    return true
  }

  onCurrentReleaseIdChanged(value: number) {
    this.releaseVersionInterceptor.set(value)
  }

  // getLandingPageModel(): Observable<LatestDataModel>{
  //   return this.releaseDataService.getLatestDataModel();
  // }
}
